import React, { useState, useEffect } from "react";
import { geoCentroid } from "d3-geo";
import {
	ComposableMap,
	Geographies,
	Geography,
	Marker,
	Annotation
} from "react-simple-maps";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ModalBasic from "../components/Modal";
import brTopoJson from "../data/br-topo.json";
import dataMap from "../data/dataMap.json";
import { useMediaQuery } from "usehooks-ts";

const geographyStyle = {
	fill: "#ECEFF1",
	backgroundColor: "yellow",
	// stroke: "white",
	// strokeWidth: 1,
	outline: "none",
	// cursor: "pointer",
	transition: "all .2s"
};

const statesWithAnnotations = {
	BR_DF: {
		annotation: { x: -10, y: -15 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	BR_RN: {
		annotation: { x: 28, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	BR_PB: {
		annotation: { x: 32, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	BR_PE: {
		annotation: { x: 50, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	BR_AL: {
		annotation: { x: 30, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	BR_SE: {
		annotation: { x: 25, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	BR_ES: {
		annotation: { x: 20, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	BR_RJ: {
		annotation: { x: 25, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	PY_AM: {
		annotation: { x: 16, y: -10 },
		tag: { fontSize: 14, x: 2, y: 0 }
	},
	PY_AA: {
		annotation: { x: 15, y: 0 },
		tag: { fontSize: 14, x: 4, y: 0 }
	},
	PY_GU: {
		annotation: { x: -90, y: 75 },
		tag: { fontSize: 14, x: 2, y: 8 }
	},
	PY_PG: {
		annotation: { x: -130, y: 30 },
		tag: { fontSize: 14, x: 4, y: 10 }
	},
	PY_CZ: {
		annotation: { x: -40, y: 100 },
		tag: { fontSize: 14, x: 0, y: 8 }
	},
	PY_NE: {
		annotation: { x: -100, y: 45 },
		tag: { fontSize: 14, x: 4, y: 8 }
	},
	PY_CE: {
		annotation: { x: -110, y: 10 },
		tag: { fontSize: 14, x: 4, y: 10 }
	},
	PY_CR: {
		annotation: { x: -100, y: -30 },
		tag: { fontSize: 14, x: -4, y: 12 }
	},
	PY_AS: {
		annotation: { x: -110, y: -10 },
		tag: { fontSize: 14, x: 4, y: 10 }
	},
	PY_MI: {
		annotation: { x: -25, y: 30 },
		tag: { fontSize: 14, x: -5, y: 10 }
	}
};
const MapChart = () => {
	const isMobile = useMediaQuery("(max-width: 600px)");
	const [modal, setModal] = useState(false);
	const [modalCity, setModalCity] = useState(false);
	const [dataMapInital] = useState(dataMap);
	const [dataMapFilter, setDataMapFilter] = useState({});

	// UF data
	const [uf, setUf] = useState('');
	const [totalCooperatives, setTotalCooperatives] = useState(0);
	const [steelTotal, setSteelTotal] = useState(0);
	const [paperTotal, setPaperTotal] = useState(0);
	const [glassTotal, setGlassTotal] = useState(0);
	const [plasticTotal, setPlasticTotal] = useState(0);
	const [totalPeople, setTotalPeople] = useState(0);
	const [citiesList, setCitiesList] = useState([]);

	// City data
	const [city, setCity] = useState('');
	const [totalCooperativesCity, setTotalCooperativesCity] = useState(0);
	const [steelTotalCity, setSteelTotalCity] = useState(0);
	const [paperTotalCity, setPaperTotalCity] = useState(0);
	const [glassTotalCity, setGlassTotalCity] = useState(0);
	const [plasticTotalCity, setPlasticTotalCity] = useState(0);
	const [, setTotalPeopleCity] = useState(0);
	const [showCityData, setShowCityData] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			try {
				document.getElementById('search-city').value = '';
			} catch (e) {
				console.log(e);
			}
		}, 100);
	}, [modalCity]);

	function convertNumberToString(value, precision) {
		let integerPart = Math.floor(value);
		let decimalPart = Math.round((value % 1) * 10 ** precision + 10 ** (precision + 1));
		integerPart = integerPart.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		decimalPart = decimalPart.toFixed(0).substring(2);
		if (decimalPart.length === 0) decimalPart = '';
		const text = integerPart + (precision === 0 ? '' : `,${decimalPart}`).replace(/N/g, '00');
		return text;
	}

	const filterDistrict = (district) => {
		if (district === 'MG') {
			return 'Minas Gerais';
		} else if (district === 'SP') {
			return 'São Paulo';
		} else if (district === 'RJ') {
			return 'Rio de Janeiro';
		} else if (district === 'BA') {
			return 'Bahia';
		} else if (district === 'PR') {
			return 'Paraná';
		} else if (district === 'RS') {
			return 'Rio Grande do Sul';
		} else if (district === 'PE') {
			return 'Pernambuco';
		} else if (district === 'CE') {
			return 'Ceará';
		} else if (district === 'PA') {
			return 'Pará';
		} else if (district === 'SC') {
			return 'Santa Catarina';
		} else if (district === 'MA') {
			return 'Maranhão';
		} else if (district === 'GO') {
			return 'Goiás';
		} else if (district === 'RN') {
			return 'Rio Grande do Norte';
		} else if (district === 'PB') {
			return 'Paraíba';
		} else if (district === 'ES') {
			return 'Espírito Santo';
		} else if (district === 'AL') {
			return 'Alagoas';
		} else if (district === 'MT') {
			return 'Mato Grosso';
		} else if (district === 'PI') {
			return 'Piauí';
		} else if (district === 'AM') {
			return 'Amazonas';
		} else if (district === 'SE') {
			return 'Sergipe';
		} else if (district === 'DF') {
			return 'Distrito Federal';
		} else if (district === 'MS') {
			return 'Mato Grosso do Sul';
		} else if (district === 'RO') {
			return 'Rondônia';
		} else if (district === 'AC') {
			return 'Acre';
		} else if (district === 'AP') {
			return 'Amapá';
		} else if (district === 'RR') {
			return 'Roraima';
		} else if (district === 'TO') {
			return 'Tocantins';
		}
		return district;
	}


	const CardDataMaterial = (props) => {
		const { nameMaterial, valueMaterial } = props;
		return (
			<Grid
				item
				xs={8}
				sx={{
					flexDirection: "column",
					display: "flex",
					textAlign: "center",
					alignContent: "center",
					justifyContent: "center",
					borderTop: "1px solid #767676",
					borderLeft: "1px solid #767676",
					borderBottom: "1px solid #767676",
				}}
			>
				<div
					style={{
						padding: "10px",
					}}
				>
					<Typography
						variant="h6"
						sx={{
							fontWeight: 600,
							fontFamily: "montserrat",
							color: "#036838;",
							fontSize: "14px",
						}}
					>
						{nameMaterial}
					</Typography>
					<Typography
						variant="P"
						sx={{
							fontFamily: "montserrat",
							color: "#1c83c3;",
							fontSize: "18px",
							fontWeight: "bold",
						}}
					>
						{valueMaterial}
					</Typography>
				</div>
			</Grid>
		);
	};

	const renderGeograph = (dataSource, countryId, countryColor) => {
		return (
			<>
				<Geographies geography={dataSource}>
					{({ geographies }) => (
						<>
							{geographies.map((geo) => {
								return (
									<Geography
										key={geo.rsmKey + "-Geography"}
										stroke="#FFF"
										geography={geo}
										onClick={() => {
											// "uf": "SP",
											// "totalCooperatives": 313,
											// "steelTotal": 12415085,
											// "paperTotal": 62420735,
											// "plasticTotal": 28320167,
											// "glassTotal": 36677832,
											// "totalPeople": 3976,
											// "citiesList"
											const [dataState] = dataMapInital.filter(
												(dataKgMap) => dataKgMap.uf === geo.properties.id
											);
											setDataMapFilter(dataState);
											setUf(dataState.uf);
											setTotalCooperatives(dataState.totalCooperatives);
											setSteelTotal(dataState.steelTotal / 1000);
											setPaperTotal(dataState.paperTotal / 1000);
											setPlasticTotal(dataState.plasticTotal / 1000);
											setGlassTotal(dataState.glassTotal / 1000);
											setTotalPeople(dataState.totalPeople);
											setCitiesList(dataState.citiesList);
											setModal(true);
											// // get data from data.json when get.props.name === data.state
											// const data = markers.filter((marker) => marker.state === geo.properties.name);
											// // get only name on data
											// const dataName = data.map((item) => item.name + ', ');
											// setModalData(dataName);
											// setModal(true);
										}}
										style={{
											default: {
												...geographyStyle,
												fill: countryColor,
											},
											hover: {
												...geographyStyle.hover,
												fill: "#A0C136",
												transition: "all 250ms",
												outline: "none",
												cursor: "pointer",
											},
											pressed: {
												...geographyStyle.pressed,
												fill: "#A0C136",
												outline: "none",
												cursor: "pointer",
											},
										}}
									/>
								);
							})}
							{/*
							{geographies.map((geo) => {
								const centroid = geoCentroid(geo);
								console.log(centroid);
								return (
									<g
										key={`${geo.rsmKey}-Marker`}
										style={{ pointerEvents: "none" }}
									>
										<Marker coordinates={centroid}>
											<text>{geo.id}</text>
										</Marker>
									</g>
								);
							})} */}

							{geographies.map((geo) => {
								const centroid = geoCentroid(geo);
								const geoId = geo.properties.id;
								const annotationOffset =
									statesWithAnnotations[`${countryId}_${geoId}`];
								const tagPosition = annotationOffset?.tag || {
									x: 2,
									y: 0,
									fontSize: 12,
								};
								return (
									<g
										key={`${geo.rsmKey}-Marker`}
										style={{ pointerEvents: "none" }}
									>
										{annotationOffset ? (
											<Annotation
												connectorProps={{
													stroke: geoId === "DF" ? "#A0C136" : "#A0C136",
													strokeWidth: 2,
												}}
												subject={centroid}
												dx={annotationOffset.annotation.x}
												dy={annotationOffset.annotation.y}
											>
												<text
													x={tagPosition.x}
													y={tagPosition.y}
													fontSize={tagPosition.fontSize}
													alignmentBaseline="middle"
													stroke={geoId === "DF" ? "#fafafa" : "#555"}
												>
													{geoId}
												</text>
											</Annotation>
										) : (
											<Marker coordinates={centroid}>
												<text
													x={tagPosition.x}
													y={tagPosition.y}
													fontSize={tagPosition.fontSize}
													textAnchor="middle"
													stroke="#fafafa"
												>
													{geoId}
												</text>
											</Marker>
										)}
									</g>
								);
							})}
						</>
					)}
				</Geographies>
				{/* {markers.map(({ name, coordinates, markerOffset, state }) => (
					<Marker key={name} coordinates={coordinates}>
						<circle r={"2"} fill={"black"} opacity="1" onClick={() => {
							console.log("clicked");
						}} />
						<g
							fill="none"
							stroke="#FF5533"
							strokeWidth="1"
							strokeLinecap="round"
							strokeLinejoin="round"
							transform="translate(-12, -24)"
						>
							<circle cx="6" cy="10" r="3" />
							<path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
						</g>
						<text
							textAnchor="middle"
							y={markerOffset}
							style={{ fontFamily: "system-ui", fill: "#FF5533" }}
						>
							{state}
						</text>
					</Marker>
				))} */}
			</>
		);
	};

	return (
		<div>
			<ComposableMap
				projection="geoMercator"
				projectionConfig={{
					scale: 800,
					center: [-54, -15],
				}}
				width={600}
				height={600}
			>
				{renderGeograph(brTopoJson, "BR", "#217343")}
			</ComposableMap>

			<ModalBasic open={modal} setOpen={setModal}>
				<div>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container columns={16}>
							<Grid
								item
								xs={16}
								sx={{
									textAlign: "center",
									backgroundColor: "#F3F3F3",
									width: "100%",
									padding: isMobile ? "20px" : '10px',
								}}
							>
								<Typography
									variant="h6"
									sx={{
										margin: "8px",
										fontWeight: "bold",
										fontFamily: "montserrat",
										color: "#1c83c3;",
										fontSize: 25,
										lineHeight: 1.6,
										letterSpacing: "0.0075em",
									}}
								>
									{filterDistrict(uf)}
								</Typography>
								<Typography
									variant="P"
									sx={{
										fontFamily: "montserrat",
										color: "#767676",
										margin: 0,
										fontSize: 13,
									}}
								>
									<b>{totalCooperatives}</b> COOPERATIVAS/ASSOCIAÇÕES<br></br>
									<b>{totalPeople}</b> COOPERADOS
								</Typography>
							</Grid>
							<CardDataMaterial
								nameMaterial="PLÁSTICO"
								valueMaterial={convertNumberToString(plasticTotal, 2)}
							/>
							<CardDataMaterial
								nameMaterial="METAL"
								valueMaterial={convertNumberToString(steelTotal, 2)}
							/>
							<CardDataMaterial
								nameMaterial="PAPEL"
								valueMaterial={convertNumberToString(paperTotal, 2)}
							/>
							<CardDataMaterial
								nameMaterial="VIDRO"
								valueMaterial={convertNumberToString(glassTotal, 2)}
							/>
							<Grid
								item
								xs={16}
								sx={{
									textAlign: "center",
									backgroundColor: "#F3F3F3",
									width: "100%",
									padding: "10px",
								}}
							>
								<Autocomplete
									disablePortal
									value={""}
									blurOnSelect={true}
									id="search-city"
									options={citiesList}
									renderInput={(params) => {
										return (
											<TextField
												{...params}
												id={params.label}
												label="Buscar Cidade"
											/>
										);
									}}
									onChange={(event, newValue) => {
										if (newValue) {
											const cityData = dataMapFilter.cities.find(
												(city) => city.city === newValue.label
											);
											if (cityData) {
												setCity(cityData.city);
												setTotalCooperativesCity(cityData.totalCooperatives);
												setTotalPeopleCity(cityData.totalPeople);
												setPlasticTotalCity(cityData.plasticTotal / 1000);
												setSteelTotalCity(cityData.steelTotal / 1000);
												setPaperTotalCity(cityData.paperTotal / 1000);
												setGlassTotalCity(cityData.glassTotal / 1000);

												let totalMaterials =
													cityData.plasticTotal +
													cityData.steelTotal +
													cityData.paperTotal +
													cityData.glassTotal;
												totalMaterials = totalMaterials / 1000;
												if (totalMaterials > 0) {
													setShowCityData(true);
												} else {
													setShowCityData(false);
												}

												setModalCity(true);
											}
										}
									}}
									noOptionsText="Nenhuma cidade encontrada"
								/>
							</Grid>

							<Grid
								item
								xs={16}
								sx={{
									textAlign: "center",
									backgroundColor: "#F3F3F3",
									width: "100%",
									padding: "10px",
								}}
							>
								<Typography
									variant="h6"
									sx={{
										fontWeight: "bold",
										fontFamily: "montserrat",
										color: "#767676",
										fontSize: "0.7rem",
									}}
								>
									ACUMULADO 2020/2021 | Números em Toneladas
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</div>
			</ModalBasic>

			<ModalBasic open={modalCity} setOpen={setModalCity}>
				<div>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container columns={16}>
							<Grid
								item
								xs={16}
								sx={{
									textAlign: "center",
									backgroundColor: "#F3F3F3",
									width: "100%",
									padding: "20px",
								}}
							>
								<Typography
									variant="P"
									sx={{
										fontFamily: "montserrat",
										color: "#767676",
									}}
								>
									<br></br>

									<b>
										{city} | {uf}
									</b>{" "}
									<br></br>
									<span>COOPERATIVAS/ASSOCIAÇÕES <b>{totalCooperativesCity}</b></span>
									<br></br>
									{/* {totalPeopleCity > totalCooperativesCity ? (
										<>
											COOPERADOS <b>{totalPeopleCity}</b>
										</>
									) : (
										<>
										</>
									)} */}
								</Typography>
							</Grid>
							{showCityData ? (
								<>
									<CardDataMaterial
										nameMaterial="PLÁSTICO"
										valueMaterial={convertNumberToString(plasticTotalCity, 2)}
									/>
									<CardDataMaterial
										nameMaterial="METAL"
										valueMaterial={convertNumberToString(steelTotalCity, 2)}
									/>
									<CardDataMaterial
										nameMaterial="PAPEL"
										valueMaterial={convertNumberToString(paperTotalCity, 2)}
									/>
									<CardDataMaterial
										nameMaterial="VIDRO"
										valueMaterial={convertNumberToString(glassTotalCity, 2)}
									/>
									<Grid
										item
										xs={16}
										sx={{
											textAlign: "center",
											backgroundColor: "#F3F3F3",
											width: "100%",
											padding: "10px",
										}}
									>
										<Typography
											variant="h6"
											sx={{
												fontWeight: "bold",
												fontFamily: "montserrat",
												color: "#767676",
												fontSize: "0.7rem",
											}}
										>
											ACUMULADO 2020/2021 | Números em Toneladas
										</Typography>
									</Grid>
								</>
							) : null}
						</Grid>
					</Box>
				</div>
			</ModalBasic>
		</div>
	);
};

export default MapChart;
