import {SET_DATA, SET_EMAIL, RESET_DATA} from '../actions/userActions';

const initialState = {};

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_DATA: {
		return {
			...state,
			user: action.user,
		};
	}
	case SET_EMAIL: {
		return {
			...state,
			email: action.email,
		}
	}
	case RESET_DATA:
		return initialState;
	default: {
		return state;
	}
	}
};

export default reducer;
