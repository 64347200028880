import React from "react";

import Main from "layout/Main";

import Partners from "components/Partners";
import FormDownload from "components/FormDownload";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import { useMediaQuery } from 'usehooks-ts';

function RecyclingChain() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: 'montserrat',
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile ? 0 : 7,
							}}
						>
							COLETA SELETIVA - CENÁRIO
						</Typography>
					</div>

				</SubMain>
			</div>
			<div
				style={{
					textAlign: 'center',
					width: '100%',
				}}
			>
				<SubMain>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							color: "white",
							fontFamily: 'montserrat',
							padding: 5,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: '100%',
								textAlign: 'justify',
							}}
						>
							<Typography
								variant="p"
								sx={{
									fontFamily: "montserrat",
									justifyContent: "center",
									color: "#777777",
									fontWeight: 400,
								}}
							>
								As regiões sul e sudeste, as mais ricas do Brasil, possuem os maiores percentuais de municípios com programas de coleta seletiva implementados. Em geral praticam uma coleta seletiva parcial e ainda pouco eficiente se considerados o grau de cobertura e a taxa de recuperação: o serviço é oferecido apenas em algumas regiões da cidade, além de faltar um esforço permanente de informação e educação para os cidadãos para melhorar a qualidade e a quantidade de matérias coletados.
								<br></br>
								<br></br>
								Além disso, a maioria dos Municípios que declaram adotar a coleta seletiva não possui um programa oficial associado à gestão de resíduos, apenas reconhece que os catadores de resíduos e as organizações de catadores realizam algum tipo de coleta de material reciclável. De acordo com dados do SNIS (2021), 1664 municípios declararam adotar a coleta seletiva. Em 524 destes municípios (31,5%) ela é feita por empresa contratada pela Prefeitura e/ou catadores com apoio da prefeitura. Dentre estes, em 127 municípios, a Coleta Seletiva é feita exclusivamente pelos catadores.
							</Typography>
						</div>
					</Stack>
				</SubMain>
			</div>
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default RecyclingChain;