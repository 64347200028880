import React from "react";

import Main from "layout/Main";

import Partners from "components/Partners";
import FormDownload from "components/FormDownload";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import about from "assets/about.png";

import { useMediaQuery } from 'usehooks-ts';

function About() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: "montserrat",
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile? 0 : 7,
							}}
						>
							O ATLAS BRASILEIRO DA RECILAGEM
						</Typography>
					</div>

				</SubMain>
			</div>
			<div
				style={{
					textAlign: 'center',
					width: '100%',
				}}
			>
				<SubMain>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							minHeight: "50vh",
							color: "white",
							fontFamily: "montserrat",
							padding: 5,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: isMobile ? '100%' : '50%',
								textAlign: 'justify',
							}}
						>
							<Typography
								variant="p"
								sx={{
									fontFamily: "montserrat",
									justifyContent: "center",
									color: "#777777",
									fontWeight: 400,
								}}
							>
								O ATLAS BRASILEIRO DA RECICLAGEM é um projeto inédito que disponibiliza um banco de dados seguro e rastreável, com informações que retratam o conjunto da cadeia de reciclagem dos diferentes tipos de materiais. Sua construção é realizada de forma progressiva, a partir de informações colhidas diretamente nas Associações e Cooperativas de Catadores (ACs) e em outros atores, com o objetivo de retratar com rigor a produção dos diferentes empreendimentos e permitindo a rastreabilidade das informações contidas no banco.
								<br></br>
								<br></br>
								A cada ano, o conjunto de análises sobre a cadeia da reciclagem e seus atores sobre temas específicos será disponibilizado em formato de ebook, com análises setoriais específicas que serão realizadas pela equipe técnica do ATLAS.
								<br></br>
								<br></br>
								Todas as informações contidas neste portal são atualizadas a partir dos materiais coletados e encaminhados à reciclagem pelos diversos atores da cadeia produtiva:  catadores autônomos e em lixões; pequenos e grandes atacadistas de recicláveis; consórcios e empresas gestoras de sistemas de coleta, tratamento e destinação de Resíduos Sólidos Urbanos (RSU), ampliando e aperfeiçoando o registro do panorama dos conhecimentos no território nacional.							</Typography>
						</div>
						<div
							style={{
								maxWidth: isMobile ? '100%' : '50%',
							}}
						>
							<img src={about} alt="about" style={{
								marginTop: isMobile ? '20px' : '0px',
								maxWidth: isMobile ? '100%' : '60%',
							}} />
						</div>
					</Stack>
				</SubMain>
			</div>
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default About;