import React from "react";

import { useMediaQuery } from 'usehooks-ts';

import Main from "layout/Main";

import Infographic from "components/Infographic";
import Partners from "components/Partners";
import FormDownload from "components/FormDownload";
import MapChart from "./MapChart";

function Home() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#EFEFEF",
				}}
			>
				<div
					style={{
						maxWidth: isMobile ? "100%" : "40%",
						margin: "0 auto",
						paddingTop: 20,
					}}
				>
					<MapChart />
				</div>
			</div>
			<Infographic />
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default Home;