import React from "react";

import Main from "layout/Main";

import Partners from "components/Partners";
import FormDownload from "components/FormDownload";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import focus from "assets/focus.png";

import { useMediaQuery } from 'usehooks-ts';

function Methodology() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: "montserrat",
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile ? 0 : 7,
							}}
						>
							METODOLOGIA
						</Typography>
					</div>

				</SubMain>
			</div>
			<div
				style={{
					textAlign: 'center',
					width: '100%',
				}}
			>
				<SubMain>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							minHeight: "50vh",
							color: "white",
							fontFamily: "montserrat",
							padding: 5,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: isMobile ? '100%' : '50%',
								textAlign: 'justify',
							}}
						>
							<Typography
								variant="p"
								sx={{
									fontFamily: "montserrat",
									justifyContent: "center",
									color: "#777777",
									fontWeight: 400,
								}}
							>
								Pela primeira vez, o ATLAS agrega dados de diferentes fontes, constituindo o retrato mais amplo da cadeia e dos resultados da reciclagem no Brasil. Antes de serem analisados, os dados são tratados para evitar duplicidade de informação e verificar possíveis incoerências. Diferentes análises são realizadas, incluindo os benefícios socioambientais propiciados pela reciclagem.
								<br></br>
								<br></br>
								O ATLAS disponibiliza um Mapa Georreferenciado da Catação, que localiza as cooperativas e associações de catadores e, no futuro, identificará os catadores informais no país e sua área de atuação. Além da localização, apresenta a atual situação do Parque Industrial da Reciclagem Popular, que engloba a capacidade produtiva hoje instalada, a infraestrutura disponível referente aos galpões, equipamentos disponíveis, e a situação sociodemográfica dos catadores e catadoras envolvidos nesses empreendimentos. Com isso, é possível mensurar dados que comprovam a importância deste setor na cadeia da reciclagem, revelando as condições em que os catadores e catadoras realizam o seu trabalho, ponto importante para se entender e comparar níveis de produtividade e eficiência entre diferentes modelos de tratamento de resíduos, e auxiliar decisões de investimento.
							</Typography>
						</div>
						<div
							style={{
								maxWidth: isMobile ? '100%' : '50%',
							}}
						>
							<img src={focus} alt="about" style={{
								marginTop: isMobile ? '20px' : '0px',
								maxWidth: isMobile ? '100%' : '60%',
							}} />
						</div>
					</Stack>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{

							color: "white",
							fontFamily: "montserrat",
							padding: 5,
							marginTop: -11,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: '100%',
								textAlign: 'justify',
							}}
						>
							<Typography
								variant="p"
								sx={{
									fontFamily: "montserrat",
									justifyContent: "center",
									color: "#777777",
									fontWeight: 400,
								}}
							>
								<br></br>
								<br></br>
								O Mapa da Catação traz também dados sociodemográficos dos associados/cooperados, a fim de retratar o estrato social de escolaridade e renda pertencentes a esses trabalhadores. Registram-se, ainda de maneira inicial, informações quali-quantitativas sobre os catadores autônomos, cujos registros quantitativos são ainda mais lacunares, embora existam diversos estudos monográficos. Entretanto, pretende-se, a partir do próximo ano, que dados primários sobre esta população possam ser coletados nas cinco regiões do país, no intuito de entendimento de onde estão e como atuam, de modo a se buscar soluções para sua inclusão efetiva na cadeia produtiva.
								<br></br>
								<br></br>
								Neste ano I, o ATLAS BRASILEIRO DA RECICLAGEM foi montado a partir da agregação de bancos de dados já existentes, a maioria deles organizados em torno de programas de logística reversa de embalagens (LRE). O ATLAS compila dados dos maiores programas de LRE do Brasil, quais sejam, dados do Programa “Reciclar pelo Brasil”, gerenciado pela Associação Nacional dos Catadores de Materiais Recicláveis (ANCAT), do Programa “Dê a Mão para o Futuro” (DAMF), gerenciado pela Associação Brasileira da Indústria da Higiene Pessoal, Perfumaria e Cosméticos (ABIPECH), do Programa de LRE da Coalizão Embalagens e da Pesquisa Ciclosoft realizada pelo Compromisso Empresarial pela Reciclagem (CEMPRE).
								<br></br>
								<br></br>
								O ATLAS reúne ainda dados de cadastro do Movimento Nacional de Catadores de Materiais Recicláveis (MNCR), além de dados registrados em programas públicos de reciclagem inclusiva, a saber, o Programa Bolsa Reciclagem, criado em 2012, em Minas Gerais, como um programa público de pagamento de serviço ambiental urbano aos catadores organizados em associações e cooperativas no estado de Minas Gerais, e do Programa Auxílio Catador, criado em 2020 como um Programa Estadual de Auxílio à renda decorrente dos serviços ambientais prestados pelos catadores durante a pandemia, atualmente transformado em um programa permanente. Agrega ainda as informações relativas ao controle de dados e resultados da Superintendência de Limpeza Pública do Distrito Federal (SLU/DF), que, no ano de 2018, fechou o lixão que era usado para a disposição final de resíduos na capital federal e organizou um programa de coleta seletiva inclusiva e solidária no território, com a contratação das associações e cooperativas de catadores como prestadores de serviço de coleta seletiva e de triagem de resíduos sólidos urbanos recicláveis.
								<br></br>
								<br></br>
								Por fim, o ATLAS agrega também dados coletados pelos demais atores da cadeia da reciclagem de resíduos, tais como os comerciantes intermediários e a indústria, nos diversos setores envolvidos na reciclagem de resíduos. Foram agregados ao ATLAS dados diretamente fornecidos pela Associação dos Aparistas de Papel (ANAP), pela Associação Brasileira da Indústria de Vidro (ABIVIDRO), pela TETRAPAK, em relação à reciclagem de embalagens longa vida, e pela RECICLALATAS, em relação às embalagens de alumínio. Dados relativos aos demais materiais bem como a complementação de diferentes dados foram obtidos por meio de pesquisa em relatórios técnicos e documentos científicos, os quais estão devidamente referenciados no relatório completo do ATLAS disponível no link.
								<br></br>
								<br></br>
							</Typography>
						</div>
					</Stack>
				</SubMain>
			</div>
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default Methodology;