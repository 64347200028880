import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import menuReducer from "./menuReducer";

const rootReducer = combineReducers({
	authReducer: authReducer,
	userReducer: userReducer,
	menuReducer: menuReducer,
});

export default rootReducer;
