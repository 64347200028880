import React from "react";

import Main from "layout/Main";

import Partners from "components/Partners";
import FormDownload from "components/FormDownload";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import { useMediaQuery } from 'usehooks-ts';

function Lib() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: 'montserrat',
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile ? 0 : 7,
							}}
						>
							BIBLIOTECA
						</Typography>
					</div>

				</SubMain>
			</div>
			<div
				style={{
					textAlign: 'center',
					width: '100%',
				}}
			>
				<SubMain>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							color: "white",
							fontFamily: 'montserrat',
							padding: 5,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: '100%',
								textAlign: 'justify',
							}}
						>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '15px', fontFamily: 'montserrat' }}>&nbsp;</p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><strong><span style={{ fontSize: '21px', color: 'black' }}>Referências Bibliográficas</span></strong></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ABEAÇO - Associação Brasileira de Embalagens de Aço. 2020. <strong>Sustentabilidade</strong>. Disponível em: &lt;</span><a target="_blank"  href="http://abeaco.org.br/sustentabilidade/"><span style={{ color: '#1155CC' }}>http://abeaco.org.br/sustentabilidade/</span></a><span style={{ color: 'black' }}>&gt;. Acesso em: 29 de nov. de 2022.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ABIPET - Associação Brasileira da Indústria do PET. 2022. 12º <strong>Censo da Reciclagem do PET no Brasil.</strong> Disponível em: &lt;</span><a target="_blank"  href="https://abipet.org.br/"><span style={{ color: '#1155CC' }}>https://abipet.org.br/</span></a><span style={{ color: 'black' }}>&gt;. Acesso em: 29 de nov. de 2022.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ABIPLAST - Associação Brasileira da Indústria do Plástico. 2021. <strong>Estudo aponta que 23,1% dos resíduos plásticos pós-consumo foram reciclados em 2020 no Brasil</strong>. Disponível em: &lt;</span><a target="_blank"   href="http://www.abiplast.org.br/noticias/estudo-aponta-que-231-dos-residuos-plasticos-pos-consumo-foram-reciclados-em-2020-no-brasil/"><span style={{ color: '#1155CC' }}>http://www.abiplast.org.br/noticias/estudo-aponta-que-231-dos-residuos-plasticos-pos-consumo-foram-reciclados-em-2020-no-brasil/</span></a><span style={{ color: 'black' }}>&gt;. Acesso em: 29 de nov. de 2022.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ABIVIDRO - Associação Brasileira das Indústrias de Vidro. <strong>Produção, Consumo e Circulação de Vidro Oco no Brasil, sua Distribuição Regional e por Segmento de Consumo</strong>. ABIVIDRO, 2021.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ABRALATAS - Associação Brasileira dos Fabricantes de Latas de Alumínio. <strong>Em uma lata, um planeta. Relatório Consolidado de práticas ESG.</strong> Abralatas, 2021. Disponível em: &lt;</span><a target="_blank"  href="https://www.abralatas.org.br/wp-content/uploads/2022/10/relatorio_ESG_ABRALATAS_2022.pdf"><span style={{ color: 'black' }}>https://www.abralatas.org.br/wp-content/uploads/2022/10/relatorio_ESG_ABRALATAS_2022.pdf</span></a><span style={{ color: 'black' }}>&gt;. Acesso em: 29 de nov. de 2022.&nbsp;</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ABAL - Associação Brasileira do Alumínio. 2019. <strong>Sustentabilidade: reciclagem</strong>. Disponível em: &lt;</span><a target="_blank"  href="https://abal.org.br/sustentabilidade/reciclagem/reciclagem-no-brasil/"><span style={{ color: 'black' }}>https://abal.org.br/sustentabilidade/reciclagem/reciclagem-no-brasil/</span></a><span style={{ color: 'black' }}>&gt;. Acesso em: 29 de nov. de 2022.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ABRELPE, Associação Brasileira de Empresas de Limpeza Pública e Resíduos Especiais. <strong>Panorama dos Resíduos Sólidos no Brasil</strong>; ABRELPE: São Paulo, Brasil, 2021; p. 16.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>ANAP - Associação Nacional dos Aparistas de Papel. <strong>Relatório Anual 2019</strong>. Associação Nacional dos Aparistas de Papel, 2019. Disponível em: &lt;https://anap.org.br/relatorio-anual-2019/&gt;. Acesso em: 28 de nov. de 2022.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>CEMPRE - Compromisso Empresarial para a Reciclagem. <strong>Cempre Review 2019</strong>. CEMPRE: São Paulo, 2019.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>CEMPRE - Compromisso Empresarial para a Reciclagem. 2021. <strong>Taxas de reciclagem</strong>. Disponível em: &lt;</span><a target="_blank"  href="https://cempre.org.br/taxas-de-reciclagem/"><span style={{ color: '#1155CC' }}>https://cempre.org.br/taxas-de-reciclagem/</span></a><u><span style={{ color: '#1155CC' }}>&gt;.&nbsp;</span></u><u><span style={{ color: '#1155CC' }}>Acesso em: 29 de nov. de 2022.</span></u><span style={{ color: 'black' }}>&nbsp;</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>BIR - Bureau of International Recycling. 2021. <strong>Paper and board recycling in 2019</strong>.&nbsp;</span><span style={{ color: 'black' }}>Disponível em: &lt;</span><a target="_blank"  href="https://www.bir.org/publications/facts-figures/download/832/140/36?method=view"><span style={{ color: '#1155CC' }}>https://www.bir.org/publications/facts-figures/download/832/140/36?method=view</span></a><u><span style={{ color: '#1155CC' }}>&gt;.&nbsp;</span></u><u><span style={{ color: '#1155CC' }}>Acesso em: 29 de nov. de 2022.&nbsp;</span></u></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>BIR - Bureau of International Recycling. 2021. <strong>World steel recycling in figures 2016 - 2020</strong>.&nbsp;</span><span style={{ color: 'black' }}>Disponível em: &lt;</span><a target="_blank"  href="https://www.bir.org/publications/facts-figures/download/821/175/36?method=view"><span style={{ color: '#1155CC' }}>https://www.bir.org/publications/facts-figures/download/821/175/36?method=view</span></a><u><span style={{ color: '#1155CC' }}>&gt;. Acesso em: 29 de nov. de 2022.</span></u></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>EIGENHEER, E.; FERREIRA, J.A.; ADLER, R.R. <strong>Reciclagem: Mito e Realidade</strong>; In-Fólio: Rio de Janeiro, Brazil, 2005.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>GONÇALVES-DIAS, Sylmara Lopes Francelino (org.). <strong>Caderno 2 - Diagnóstico da coleta seletiva do município de São Paulo</strong>. 1. ed. São Paulo: Escola de Artes, Ciências e Humanidades, Universidade de São Paulo, 2020.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>IBÁ - Indústria Brasileira de Árvores. 2017. <strong>Reciclagem</strong>. Disponível em: &lt;</span><a target="_blank"  href="https://www.iba.org/reciclagem"><span style={{ color: '#1155CC' }}>https://www.iba.org/reciclagem</span></a><u><span style={{ color: '#1155CC' }}>&gt;.&nbsp;</span></u><u><span style={{ color: '#1155CC' }}>Acesso em: 29 de nov. de 2022.</span></u></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>KAUR, A.; MISHRA, A.K. Management and Recycling Routes in Plastic Waste Management Framework: A World Prospective.&nbsp;</span><strong><span style={{ color: 'black' }}>Plast. Polym. Techno</span></strong><span style={{ color: 'black' }}>l. PAPT 2014, 3, 26–30</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>LIMA, F.P.A.; VARELLA, C.V.S.; OLIVEIRA, F.G.; PARREIRA, G.; RUTKOWSKI, J.E. Tecnologias Sociais da Reciclagem: Efetivando Políticas de Coleta Seletiva com Catadores. In <strong>Gerais: Revista Interinstitucional de Psicologia</strong>, 4 (2),Ed. Especial; UFMG: Belo Horizonte, Brazil, 2011; pp. 131–146.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>MONTENEGRO, Marcelo; VIANNA, Manoela; TELES, Daisy (org). <strong>Atlas do Plástico: Fatos e números sobre o mundo dos polímeros sintéticos</strong>. Rio de Janeiro: Fundação Heinrich Boll no brasil, 2020.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>RUTKOWSKI, J.E. INCLUSIVE PACKAGING RECYCLING SYSTEMS: IMPROVING SUSTAINABLE WASTE MANAGEMENT FOR A CIRCULAR ECONOMY. <strong>Detritus</strong> / Volume 13 - 2020 / pages 29-46&nbsp;</span><a target="_blank"  href="https://doi.org/10.31025/2611-4135/2020.14037"><span style={{ color: '#0563C1' }}>https://doi.org/10.31025/2611-4135/2020.14037</span></a></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>RUTKOWSKI, J.E; RUTKOWSKI, E.W. Recycling in Brasil: Paper and Plastic Supply Chain. <strong>Resources</strong> 2017, 6, 43; doi:10.3390/resources6030043.&nbsp;</span><a target="_blank"  href="http://www.mdpi.com/journal/resources"><span style={{ color: '#0563C1' }}>www.mdpi.com/journal/resources</span></a></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>SCHEINBERG, A., NESIC, J., SAVAIN, R., LUPPI, P., SINNOTT, P., PETEAN, F., POP F. (2016) From collision to collaboration – Integrating informal recyclers and reuse operators in Europe: a review.&nbsp;</span><strong><span style={{ color: 'black' }}>Waste Management&amp; Research</span></strong><span style={{ color: 'black' }}>&nbsp;2016, Vol.34 (9)820-839. DOI:10.1177/0734242X16657608</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>SLU/DF - Serviço de Limpeza Urbana. <strong>Ampliação da coleta seletiva e impactos da pandemia nos serviços de limpeza urbana. Relatório Anual de 2020</strong>. Serviço de Limpeza Urbana do Distrito Federal, 2020. Disponível em: &lt;</span><a target="_blank"  href="https://www.slu.df.gov.br/wp-content/uploads/2021/03/RELATORIO-ANUAL-2020.pdf"><span style={{ color: 'black' }}>https://www.slu.df.gov.br/wp-content/uploads/2021/03/RELATORIO-ANUAL-2020.pdf</span></a><span style={{ color: 'black' }}>&gt;. Acesso em: 28 de nov. de 2022.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>SNIS. <strong>Diagnóstico Temático Manejo de Resíduos Sólidos Urbanos. Sistema Nacional de Informações sobre Saneamento – Infraestrutura</strong>, ano de referência: 2020.BRASIL.MMA.2022.&nbsp;</span><a target="_blank"  href="http://www.snis.gov.br/diagnosticos"><span style={{ color: '#0563C1' }}>http://www.snis.gov.br/diagnosticos</span></a><span style={{ color: 'black' }}>.&nbsp;</span></p>
						</div>
					</Stack>
				</SubMain>
			</div>
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default Lib;