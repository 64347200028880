import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "rgb(255, 255, 255)",
	border: "2px solid #1c83c3",
	boxShadow:
		"rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px",
	maxWidth: "70%",
	// p: 4,
	// height: '70vh',
	// minHeight: '70vh'
};
export default function ModalBasic(props) {
	const { open, setOpen, children } = props;

	const handleClose = () => setOpen(false);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			scroll="body"
			style={{ overflow: "scroll" }}
		>
			<>
				<Box sx={{ ...style }}>
					{children}
					<HighlightOffIcon
						onClick={handleClose}
						style={{
							position: "absolute",
							top: "-10",
							right: "-10",
							cursor: "pointer",
							color: "#fff",
							padding: 10,
							backgroundColor: "#1c83c3",
						}}
						onMouseOver={(e) => (e.currentTarget.style.color = "#000")}
						onMouseOut={(e) => (e.currentTarget.style.color = "#fff")}
					/>
				</Box>
			</>
		</Modal>
	);
}
