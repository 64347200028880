import {
	STORE_JWT,
	RESET,
} from '../actions/authActions'

const initialState = {
	token: undefined,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
	case STORE_JWT: {
		return {
			...state,
			token: action.token,
		}
	}
	case RESET: {
		return initialState
	}
	default: {
		return state;
	}
	}
};


export default authReducer;