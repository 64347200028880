import Typography from "@mui/material/Typography";
import flow from "assets/flow.png";
import { useMediaQuery } from 'usehooks-ts';

const InfoGraphic = () => {
	const isMobile = useMediaQuery("(max-width: 600px)");

	return (
		<div
			style={{
				textAlign: "center",
				marginTop: 50,
				marginBottom: 50,
			}}
		>
			<Typography
				variant="h4"
				sx={{ color: "#1c83c3", fontWeight: "bold", fontFamily: "montserrat" }}
			>
				INFOGRÁFICO
			</Typography>
			<Typography
				variant="h5"
				sx={{ color: "#1c83c3", fontWeight: 600, fontFamily: "montserrat" }}
			>
				ECOLÓGICO DA RECICLAGEM
			</Typography>
			<Typography
				variant="h6"
				sx={{ color: "#1c83c3", fontFamily: "montserrat" }}
			>
				Entenda os KPI's que impactam diretamente na economia de recursos
				naturais
			</Typography>
			<img
				src={flow}
				alt="Fluxo de reciclagem"
				style={{ width: isMobile ? "100%" : "80%" }}
			/>
			<div id="parceiros" />
		</div>
	);
}


export default InfoGraphic;
