import React from "react";

import Main from "layout/Main";

import Partners from "components/Partners";
import FormDownload from "components/FormDownload";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import { useMediaQuery } from 'usehooks-ts';

import cadeia from "assets/cadeia.png";

function RecyclingChain() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: 'montserrat',
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile ? 0 : 7,
							}}
						>
							CADEIA DA RECICLAGEM
						</Typography>
					</div>

				</SubMain>
			</div>
			<div
				style={{
					textAlign: 'center',
					width: '100%',
				}}
			>
				<SubMain>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							color: "white",
							fontFamily: 'montserrat',
							padding: 5,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: '100%',
								textAlign: 'justify',
								fontFamily: 'montserrat',
								color: "#000000",

							}}
						>
							<div>
								<Typography
									variant="p"
									style={{
										fontFamily: 'montserrat',
									}}
								>
									A figura abaixo traz uma representação gráfica das cadeias de reciclagem tais como o ATLAS pretende retratar. Como se vê na figura, a cadeia de reciclagem dos resíduos no Brasil pode ser entendida como sendo formada por duas cadeias distintas e complementares: uma cadeia de serviços, que engloba a gestão integrada e sustentável dos resíduos, o que inclui a coleta seletiva dos resíduos recicláveis, e por uma cadeia de valor, na qual, por meio de processos de pré-beneficiamento (triagem, classificação, prensagem), os resíduos são transformados novamente em matéria prima secundária utilizada na indústria. Como demonstrado na figura, os catadores de materiais recicláveis atuam exatamente na interface entre estas cadeias, fazendo um elo entre elas.</Typography>
								<Typography
									variant="p"
									style={{
										fontFamily: 'montserrat',
									}}
								>
									Cadeia produtiva da reciclagem dos resíduos no Brasil. A linha azul delimita a cadeia de serviços a qual, junto com a cadeia de valor, delimitada pela linha vermelha, conformam a cadeia da reciclagem de resíduos</Typography>
								<center>
									<img
										src={cadeia}
										style={{
											maxHeight: isMobile ? 100 : 400,
											cursor: 'pointer',
										}}
										onClick={() => {
											window.open(cadeia);
										}}
									/>
									<Typography
										variant="span"
										style={{
											fontFamily: 'montserrat',
										}}
									>
										<br></br>
										Fonte: Rutkowski, J.E. (2021).
										<br></br>
									</Typography>
								</center>
								<Typography
									variant="p"
									style={{
										fontFamily: 'montserrat',
									}}
								>
									<br></br>

									Por isso, na construção e na elaboração do ATLAS optou-se por iniciar a análise, exatamente, neste elo entre a cadeia de serviço e a cadeia de valor da reciclagem de resíduos. Para além da importância histórica dos atores que compõem este elo – os(as) catadores(as) de materiais recicláveis e seus empreendimentos –, a qual merece ser devidamente reconhecida, outra razão que justifica esta escolha é o fato de que os dados de comercialização dos resíduos recicláveis pelas ACs e pelas empresas recicladoras intermediárias, seja de forma direta ou por meio de créditos de reciclagem, é que têm sido utilizados como base de comprovação das metas de reciclagem em todos os programas de logística reversa de embalagens em vigor no país. Assim, estes são os dados mais confiáveis e rastreáveis a que se tem acesso no momento.
								</Typography>
							</div>
						</div>
					</Stack>
				</SubMain>
			</div>
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default RecyclingChain;