import { applyMiddleware } from "redux";
import { legacy_createStore as createStore} from 'redux'
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers/index";

const persistConfig = {
	key: "atlas-root",
	storage: storage,
	whitelist: [
		"authReducer",
		"userReducer",
		"menuReducer"
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store;

if (process.env.NODE_ENV === "development") {
	store = createStore(persistedReducer, applyMiddleware(createLogger()));
} else {
	store = createStore(persistedReducer);
}

let persistor = persistStore(store);

export { store, persistor };
