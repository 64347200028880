import React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from 'usehooks-ts';

import SubMain from "layout/SubMain";

// import fakeLogo from "assets/fake_logo.png";
import logo_anap from "assets/logo_anap.png";
import logo_avina from "assets/logo_avina.png";
import logo_cempre from "assets/logo_cempre.png";
import logo_coa from "assets/logo_coa.png";
import logo_damf from "assets/logo_damf.png";
import logo_ics from "assets/logo_ics.png";
import logo_insea from "assets/logo_insea.png";
import logo_mapa_sa from "assets/logo_mapa_sa.png";
import logo_MNCR from "assets/logo_MNCR.png";
import logo_coca_cola from "assets/logo_coca_cola.png";

const Partners = () => {
	const isMobile = useMediaQuery("(max-width: 600px)");

	const styleImg = {
		width: 150,
		margin: 5,
	};

	return (
		<div
			style={{
				textAlign: "center",
				backgroundColor: "#efefef",
				width: "100%",
			}}
		>
			<SubMain>
				<Box sx={{ flexGrow: 1, width: "100%" }}>
					<Grid
						container
						sx={{ justifyContent: "center" }}
						columns={{ xs: 4, md: 12 }}
					>
						<div
							style={{
								// borderRight: isMobile ? '' : '3px solid #1c83c3',
								paddingRight: isMobile ? "" : 20,
							}}
						>
							<Typography
								variant="h6"
								sx={{
									color: "#1c83c3",
									fontWeight: 600,
									fontFamily: "montserrat",
								}}
							>
								INVESTIDORES AMBIENTAIS
							</Typography>
							<Grid
								container
								sx={{ justifyContent: "center" }}
								columns={{ xs: 4, md: 12 }}
							>
								<Grid item>
									<img src={logo_ics} alt="logo ics" style={styleImg} />
								</Grid>
								<Grid item>
									<img src={logo_avina} alt="logo avina" style={styleImg} />
								</Grid>
								<Grid item>
									<img
										src={logo_coca_cola}
										alt="logo coca cola"
										style={styleImg}
									/>
								</Grid>
							</Grid>
						</div>
						<div
							style={{
								paddingLeft: isMobile ? "" : 20,
							}}
						>
							<Typography
								variant="h6"
								sx={{
									color: "#1c83c3",
									fontWeight: 600,
									fontFamily: "montserrat",
								}}
							>
								PARCEIROS COLABORADORES
							</Typography>
							<Grid
								container
								sx={{ justifyContent: "center" }}
								columns={{ xs: 4, md: 12 }}
							>
								<Grid item>
									<img src={logo_anap} alt="logo anap" style={styleImg} />
								</Grid>
								<Grid item>
									<img src={logo_cempre} alt="logo cempre" style={styleImg} />
								</Grid>
								<Grid item>
									<img
										src={logo_coa}
										alt="logo coalização de embalagens"
										style={styleImg}
									/>
								</Grid>
								<Grid item>
									<img
										src={logo_damf}
										alt="logo dê a mão para o futuro"
										style={styleImg}
									/>
								</Grid>
								<Grid item>
									<img src={logo_insea} alt="logo insea" style={styleImg} />
								</Grid>
								<Grid item>
									<img src={logo_mapa_sa} alt="logo mapa sa" style={styleImg} />
								</Grid>
								<Grid item>
									<img src={logo_MNCR} alt="logo MNCR" style={styleImg} />
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Box>
			</SubMain>
		</div>
	);
};

export default Partners;
