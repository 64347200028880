export const MENU_STORE = "MENU_STORE";
export const MENU_RESET = "MENU_RESET";

export const menuStore = (menu) => ({
	type: MENU_STORE,
	menuIsOpen: menu,
});

export const reset = () => ({
	type: MENU_RESET,
});
