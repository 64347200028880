import React from 'react';

const SubMain = (props) => {
	const { children } = props;
	return (
		<div
			style={{
				maxWidth: '1200px',
				padding: '20px',
				margin: 'auto',
			}}
		>
			{children}
		</div>
	);
}

export default SubMain;