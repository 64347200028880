import React from "react";

import Main from "layout/Main";

import Partners from "components/Partners";
import FormDownload from "components/FormDownload";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import { useMediaQuery } from 'usehooks-ts';

function Lib() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: 'montserrat',
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile ? 0 : 7,
							}}
						>
							COLABORE
						</Typography>
					</div>

				</SubMain>
			</div>
			<div
				style={{
					textAlign: 'center',
					width: '100%',
				}}
			>
				<SubMain>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							color: "white",
							fontFamily: 'montserrat',
							padding: 5,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: '100%',
								textAlign: 'justify',
							}}
						>
							<Typography
								variant="p"
								sx={{
									fontFamily: "montserrat",
									justifyContent: "center",
									color: "#777777",
									fontWeight: 400,
								}}
							>
								Com a elaboração de um sólido e acessível produto sobre a reciclagem no Brasil, os investidores do ATLAS BRASILEIRO DA RECICLAGEM terão diversas formas de retribuição, além de informações úteis e confiáveis sobre o setor e o reconhecimento da sua marca na consolidação da economia circular, logística reversa e responsabilidade ambiental no Brasil.
								<br></br>
								<br></br>
								As empresas e outras organizações interessadas em patrocinar o ATLAS BRASILEIRO DA RECICLAGEM podem contribuir em três modalidades.
								Entre em contato conosco para conhecer as modalidades e retribuições.
							</Typography>
						</div>
					</Stack>
				</SubMain>
			</div>
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default Lib;