import {
	MENU_STORE,
	MENU_RESET,
} from '../actions/menuActions';

const initialState = {
	menuIsOpen: true,
};

const menuReducer = (state = initialState, action) => {
	switch (action.type) {
	case MENU_STORE: {
		return {
			...state,
			menuIsOpen: action.menuIsOpen,
		}
	}
	case MENU_RESET: {
		return initialState
	}
	default: {
		return state;
	}
	}
};


export default menuReducer;