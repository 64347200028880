import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	// Navigate,
} from "react-router-dom";
// import { useSelector } from "react-redux";
// import isValidToken from "util/isValidToken";

import HomePage from "pages/Home";
import AboutPage from "pages/About";
import MethodologyPage from "./pages/Methodology";
import PartnersPage from "./pages/PartnersPage";
import RecyclingNumbers from "./pages/RecyclingNumbers";
import RecyclingChain from "./pages/RecyclingChain";
import Lib from "./pages/Lib";
import Collaborate from "./pages/Collaborate";
import RecyclingChain2 from "./pages/RecyclingChain2";

const RoutesApp = () => {
	// const token = useSelector((state) => state.authReducer.token);
	// const validToken = token ? isValidToken(token) : false;

	return (

		<Router>
			<div>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/sobre" element={<AboutPage />} />
					<Route path="/metodologia" element={<MethodologyPage />} />
					<Route path="/parceiros" element={<PartnersPage />} />
					<Route path="/reciclagem-em-numeros" element={<RecyclingNumbers />} />
					<Route path="/coleta-seletiva" element={<RecyclingChain />} />
					<Route path="/biblioteca" element={<Lib />} />
					<Route path="/colabore" element={<Collaborate />} />
					<Route path="/cadeia" element={<RecyclingChain2 />} />
					{/* {validToken ? (
						<>
							<Route path="/home" element={<HomePage />} />

						</>
					) : (
						<Route path="/" element={<Navigate to="/" />} />
					)} */}
					{/* <Route path="*" element={<Navigate to="/" />} /> */}
				</Routes>
			</div>
		</Router>
	);
};

export default RoutesApp;
