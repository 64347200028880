export const SET_DATA = 'USER_SET_USER_DATA';
export const SET_EMAIL = 'USER_SET_USER_EMAIL';
export const RESET_DATA = 'USER_RESET_USER_DATA';

export const setUserData = user => ({
	type: SET_DATA,
	user: user,
});

export const storeEmail = email => ({
	type: SET_EMAIL,
	email: email
})

export const reset = () => ({
	type: RESET_DATA,
})