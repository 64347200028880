import React from "react";

import Main from "layout/Main";

import Partners from "components/Partners";
import FormDownload from "components/FormDownload";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import { useMediaQuery } from 'usehooks-ts';

function Methodology() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: 'montserrat',
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile ? 0 : 7,
							}}
						>
							RECICLAGEM EM NÚMEROS
						</Typography>
					</div>

				</SubMain>
			</div>
			<div
				style={{
					textAlign: 'center',
					width: '100%',
				}}
			>
				<SubMain>
					<Stack
						justifyContent="space-around"
						alignItems="center"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							color: "white",
							fontFamily: 'montserrat',
							padding: 5,
						}}
						wrap="wrap"
					>
						<div
							style={{
								maxWidth: '100%',
								textAlign: 'justify',
							}}
						>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>O Atlas Brasileiro da Reciclagem informa o total de cada material reciclado no Brasil, nos anos de 2020 e 2021. Traz também uma estimativa do total de resíduos coletados seletivamente no Brasil, bem como do índice de rejeito deste resíduo coletado. E apresenta uma panoramaâmica da situação do mercado de reciclagem em cada região do Brasil, comparando quantidades comercializadas e preços de venda praticados em cada uma, para cada tipo de material.</span></p>
							<p style={{ marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>O Atlas Brasileiro da Reciclagem informa a porcentagem total de reciclagem dos principais materiais encontrados no RSU no Brasil, nos anos de 2020 e 2021, segundo dados da indústria transformadora. Ainda não foi possível nesta edição, quantificar exatamente este total, já que os dados de produção das embalagens produzidas a partir de cada matéria prima não são ainda facilmente acessados. Da mesma maneira, não há dados mais precisos sobre o total de resíduos coletados seletivamente no Brasil, bem como do índice de rejeito deste resíduo coletado. Estes são dados cuja coleta e tratamento esperamos aprimorar na próxima edição do ATLAS, a partir da coleta de dados primários e outros cruzamentos de dados. &nbsp; Esperamos também, nas próximas edições, apresentar um panorama da situação do mercado de reciclagem em cada região do Brasil, comparando quantidades comercializadas e preços de venda praticados em cada uma, para cada tipo de material.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>A reciclagem de resíduos é considerada uma grande fonte de economia de energia e promotora de recuperação de CO2. Além disso, gera empregos e muda os mercados no mundo. O índice de reciclagem de papel no Brasil, por exemplo, é um dos maiores do mundo. Em 2018, cerca de 48% do papel produzido no país (5,1 milhões de toneladas) retornaram para o processo produtivo.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>O crescimento consistente da indústria da reciclagem, observado na maior parte das duas primeiras décadas dos anos 2000, foi fortemente impactado pela pandemia. Em 2020, observou-se, em comparação ao ano de 2019, diminuição de 4,9% e 11,7% no número de empresas e empregos na cadeia de reciclagem de plástico, respectivamente.&nbsp;</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>O Quadro abaixo resume alguns dados quantitativos significativos da cadeia da reciclagem.</span></p>
							<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ fontSize: '16px', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
							<table style={{ border: 'none', borderCollapse: 'collapse' }}>
								<tbody>
									<tr>
										<td colSpan={3} style={{ borderTop: '1pt solid rgb(127, 127, 127)', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: 'none', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><strong><span style={{ color: 'black' }}>DADOS SIGNIFICATIVOS SOBRE A RECICLAGEM NO BRASIL</span></strong></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><strong><span style={{ color: 'black' }}>Dados</span></strong></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><strong><span style={{ color: 'black' }}>Fontes</span></strong></p>
										</td>
									</tr>
									<tr>
										<td colSpan={3} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><strong><span style={{ color: 'black' }}>O mercado da reciclagem</span></strong></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid rgb(127, 127, 127)', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>O valor do PET pago pelos intermediários às ACs é, em média, 400% superior ao valor pago ao catador individual</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>Rutkowski, Rutkowski, 2017</span></p>
										</td>
									</tr>
									<tr>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid rgb(127, 127, 127)', padding: '5pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>Há uma variação de preços dos materiais ao longo do ano e entre as regiões do Brasil</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid rgb(127, 127, 127)', padding: '5pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022; Rutkowski, Rutkowski, 2017</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}><br /><br /></td>
									</tr>
									<tr>
										<td colSpan={3} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><strong><span style={{ color: 'black' }}>A atuação dos Catadores</span></strong></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>9 em cada 10 kgs de embalagens recicladas chegam à indústria de reciclagem por meio do trabalho dos catadores</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>Dias, Vallin, Alves, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>64% das unidades de triagem municipais são geridas por associações ou cooperativas de catadores</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>SNIS, 2020</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={3} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>72,6% dos catadores associados em cooperativas têm &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ATLAS, 2022</span></p>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>escolaridade de até o ensino fundamental completo</span></p>
										</td>
									</tr>
									<tr>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>55% dos/as catadores/as não foram alfabetizados ou possuem ensino fundamental incompleto</span></p>
										</td>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>ATLAS, 2022.</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={3} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><strong><span style={{ color: 'black' }}>PARQUE Industrial da Reciclagem Popular</span></strong></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>Foram identificadas 2018 associações e cooperativas de catadores/as de materiais recicláveis em funcionamento no Brasil, sendo que 82% delas estão legalmente formalizadas</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>52% das ACs possuem galpões próprios (ou em concessão) para trabalhar, mas apenas 36% possuem os equipamentos básicos para maior produtividade, e somente 21% possuem, ao mesmo tempo, os equipamentos básicos e o galpão para trabalhar.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>A produtividade média por catador em 2021 foi de 1,96 ton/trabalhador/mês, com variação de cerca de 0,85 a 7,1 ton/trabalhador/mês.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>A produtividade média por catador associado/cooperado é de 2,2 toneladas em organizações que possuem os equipamentos básicos e cerca de 1 tonelada/trabalhador/mês em organizações que atuam sem os equipamentos básicos.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>A média de catadores das cooperativas/associações é de 24, sendo que metade delas têm até 15 catadores.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>No ano de 2021, a remuneração média dos catadores associados/cooperados foi de R$1.392,91, 27% acima do salário-mínimo vigente no Brasil á época.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>A remuneração média dos catadores vinculados a ACs que tinham contrato com o poder público em 2021, era de cerca de R$1.200,00, enquanto este valor desce para R$941,28 quando as ACs não têm contrato com o poder público.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={3} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><strong><span style={{ color: 'black' }}>Taxas de recuperação</span></strong></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>Em 2021 a taxa de recuperação de recicláveis por associação/cooperativa variou de 0,162 a 370 toneladas.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>ATLAS, 2022</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>No Distrito Federal, em 2020, o índice de rejeitos das cooperativas que fazem o trabalho de coleta e triagem de materiais recicláveis foi de 18,3%, enquanto o índice de rejeitos das cooperativas que realizam apenas a triagem dos materiais coletados por empresas foi de 63%.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><span style={{ color: 'black' }}>SLU/DF, 2020</span></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>Dos 82,5 milhões de toneladas de resíduos sólidos urbanos produzidos em 2020 no Brasil, mais de 80% foram materiais reaproveitáveis e recicláveis.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><a target="_blank"  href="https://abrelpe.org.br/panorama-2021/"><span style={{ color: 'blue' }}>ABRELPE, 2021</span></a></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat' }}><span style={{ color: 'black' }}>A fração molhada do RSU no Brasil corresponde a 51,4%, e a dita fração seca é constituída de 2,9% de metais, 13,1% de papel e papelão, 13,5% plástico e 2,4% vidro.</span></p>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid black', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><a target="_blank"  href="https://cempre.org.br/wp-content/uploads/2020/11/CEMPRE-Review2019.pdf"><span style={{ color: 'blue' }}>CEMPRE, 2019</span></a></p>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid rgb(127, 127, 127)', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'justify' }}><span style={{ color: 'black' }}>Entre 2019 e 2021 foram relatados os índices de reciclagem:&nbsp;</span></p>
											<ul style={{ marginBottom: '0cm', marginTop: '0cm' }} type="disc">
												<li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', color: 'black', textAlign: 'justify', verticalAlign: 'baseline' }}>papel em geral:&nbsp;<span style={{ color: 'windowtext' }}><a target="_blank"  href="https://cempre.org.br/taxas-de-reciclagem/"><span style={{ color: 'blue' }}>66,9%</span></a></span></li>
												<li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', color: 'black', textAlign: 'justify', verticalAlign: 'baseline' }}>aparas marrons (embalagens):&nbsp;<span style={{ color: 'windowtext' }}><a target="_blank"  href="https://anap.org.br/relatorio-anual-2019/"><span style={{ color: 'blue' }}>79,8%</span></a></span></li>
												<li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', color: 'black', textAlign: 'justify', verticalAlign: 'baseline' }}>latas de alumínio:&nbsp;<span style={{ color: 'windowtext' }}><a target="_blank"  href="https://cempre.org.br/taxas-de-reciclagem/"><span style={{ color: 'blue' }}>98,7%</span></a></span></li>
												<li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', color: 'black', textAlign: 'justify', verticalAlign: 'baseline' }}>latas de aço:&nbsp;<span style={{ color: 'windowtext' }}><a target="_blank"  href="https://cempre.org.br/taxas-de-reciclagem/"><span style={{ color: 'blue' }}>47,1%</span></a></span></li>
												<li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', color: 'black', textAlign: 'justify', verticalAlign: 'baseline' }}>plásticos pós-consumo:&nbsp;<span style={{ color: 'windowtext' }}><a target="_blank"  href="https://cempre.org.br/taxas-de-reciclagem/"><span style={{ color: 'blue' }}>23,1%</span></a></span></li>
											</ul>
										</td>
										<td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderImage: 'initial', borderBottom: '1pt solid rgb(127, 127, 127)', padding: '0cm 5.75pt', verticalAlign: 'top' }}>
											<p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: 'montserrat', textAlign: 'center' }}><a target="_blank"  href="https://anap.org.br/relatorio-anual-2019/"><span style={{ color: 'blue' }}>ANAP, 2019</span></a><u><span style={{ color: 'black' }}>;&nbsp;</span></u><a target="_blank"  href="https://cempre.org.br/taxas-de-reciclagem/"><span style={{ color: 'blue' }}>CEMPRE, 20</span></a><u><span style={{ color: 'black' }}>21</span></u></p>
										</td>
									</tr>
									<tr>
										<td style={{ border: 'none' }}><br /><br /></td>
										<td style={{ border: 'none' }}><br /><br /></td>
										<td style={{ border: 'none' }}><br /><br /></td>
									</tr>
								</tbody>
							</table>
						</div>
					</Stack>
				</SubMain>
			</div>
			<Partners />
			<FormDownload />
		</Main >
	);
}

export default Methodology;