import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import SubMain from "layout/SubMain";

import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { useMediaQuery } from 'usehooks-ts';

// import pdf
import pdf from 'assets/ABR_ebook-rev.1.pdf';

const FormDownload = () => {
	const isMobile = useMediaQuery("(max-width: 600px)");

	return (
		<div
			id="formDownload"
			style={{
				textAlign: "center",
				backgroundColor: "#8ab832",
				width: "100%",
			}}
		>
			<SubMain>
				<Stack
					justifyContent="space-around"
					alignItems="center"
					direction={{ xs: "column", sm: "row" }}
					spacing={{ xs: 1, sm: 2, md: 4 }}
					sx={{
						minHeight: "50vh",
						color: "white",
						fontFamily: "montserrat",
						padding: 5,
					}}
					wrap="wrap"
				>
					<div
						style={{
							maxWidth: isMobile ? "100%" : "50%",
							textAlign: isMobile ? "center" : "left",
						}}
					>
						<Typography
							variant="h4"
							sx={{
								fontFamily: "montserrat",
								fontWeight: 600,
							}}
						>
							ATLAS BR DA RECICLAGEM
						</Typography>
						<Typography
							variant="h5"
							sx={{
								fontFamily: "montserrat",
								fontWeight: 800,
								color: "#135781",
							}}
						>
							PARA BAIXAR GRATUITAMENTE
						</Typography>
						<Typography
							variant="p"
							sx={{
								fontFamily: "montserrat",
								fontSize: "1.2rem",
								justifyContent: "center",
								lineHeight: 2,
							}}
						>
							Faça agora o download gratuito do <b>Atlas Brasileiro da Reciclagem</b> e confira os dados completos sobre o <b>estado atual e as tendências da reciclagem</b>, que englobam os sistemas de coleta, as indústrias, o consumo e a análise geral deste setor no Brasil.
						</Typography>
					</div>
					<div
						style={{
							maxWidth: isMobile ? "" : "50%",
							backgroundColor: "#1c83c3",
							paddingTop: 30,
							paddingBottom: 30,
							borderRadius: 10,
							marginTop: isMobile ? 40 : 0,
						}}
					>
						<CardContent>
							<Typography
								gutterBottom
								variant="h5"
								component="div"
								sx={{
									fontFamily: "montserrat",
									fontWeight: 600,
									color: "white",
								}}
							>
								PREENCHA PARA BAIXAR
							</Typography>
							<Box
								component="form"
								sx={{
									"& > :not(style)": { m: isMobile ? 0 : 2, width: "25ch" },
								}}
								noValidate
								autoComplete="off"
							>
								<TextField
									label="Seu nome..."
									id="filled-size-normal"
									variant="filled"
									sx={{
										backgroundColor: "white",
										borderRadius: 1,
									}}
									style={{
										width: isMobile ? "100%" : "80%",
										marginBottom: 10,
									}}
								/>
								<TextField
									label="Seu e-mail..."
									id="filled-size-normal"
									variant="filled"
									sx={{
										backgroundColor: "white",
										borderRadius: 1,
									}}
									style={{
										width: isMobile ? "100%" : "80%",
										marginBottom: 10,
									}}
								/>
								<TextField
									label="Cidade / Estado"
									id="filled-size-normal"
									variant="filled"
									sx={{
										backgroundColor: "white",
										borderRadius: 1,
									}}
									style={{
										width: isMobile ? "100%" : "80%",
										marginBottom: 10,
									}}
								/>
								<TextField
									label="Empresa / Instituição"
									id="filled-size-normal"
									variant="filled"
									sx={{
										backgroundColor: "white",
										borderRadius: 1,
									}}
									style={{
										width: isMobile ? "100%" : "80%",
										marginBottom: 10,
									}}
								/>
								<Button
									variant="contained"
									size="large"
									sx={{
										backgroundColor: "#135781",
										color: "white",
										fontFamily: "montserrat",
										fontWeight: "bold",
										fontSize: 16,
										borderRadius: 1,
										"&:hover": {
											backgroundColor: "#fff",
											color: "#135781",
										},
										width: isMobile ? "100% !important" : "80% !important",
									}}
									onClick={() => {
										window.open(pdf);
									}}
								>
									BAIXAR AGORA
								</Button>
							</Box>
						</CardContent>
					</div>
				</Stack>
			</SubMain>
		</div>
	);
}

export default FormDownload;
