import React from "react";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from 'usehooks-ts';

import Main from "layout/Main";
import SubMain from "layout/SubMain";

import Partners from "components/Partners";

function PartnersPage() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#3191ca",
					height: "60px",
					borderTop: "10px solid #145f8d",
					borderBottom: "10px solid #145f8d",
				}}
			>
				<SubMain>
					<div
						style={{
							width: '100%',
						}}
					>
						<Typography
							variant="h5"
							sx={{
								fontFamily: "montserrat",
								color: "white",
								fontWeight: 'bold',
								fontSize: isMobile ? 16 : 20,
								paddingLeft: isMobile ? 0 : 7,
							}}
						>
							PARCEIROS
						</Typography>
					</div>
				</SubMain>
			</div>
			<div
				style={{
					display: "flex",
					alignContent: "center",
					justifyContent: "center",
					backgroundColor: "rgb(239 239 239)",
					minHeight: "50vh",
					flexDirection: "column",
				}}
			>
				<SubMain>

					<div
						style={{
							maxWidth: '100%',
							textAlign: 'justify',
						}}
					>
						<Typography
							variant="p"
							sx={{
								fontFamily: "montserrat",
								justifyContent: "center",
								color: "#777777",
								fontWeight: 400,
							}}
						>
							Esta edição contou com o apoio financeiro da FUNDAÇÃO AVINA e do ICS - Instituto Clima e Sociedade, a quem especialmente agradecemos por terem permitido viabilizar o projeto. Além disso, esse trabalho não teria sido possível sem a colaboração de várias entidades que cederam informações e dados que compõem o Banco de Dados do ATLAS, às quais agradecemos a confiança e a parceria em prol do desenvolvimento da reciclagem no Brasil, projeto comum de longo prazo que supera divergências mais imediatas. Foram elas: a ABIHPEC - Associação Brasileira da Indústria de Higiene Pessoal, Perfumaria e Cosméticos; a ANAP - Associação Nacional dos Aparistas de papel, o CEMPRE - Compromisso Empresarial para a Reciclagem, a COALIZÃO EMBALAGENS, a LENUM AMBIENTAL e o INSEA - Instituto Nenuca de Desenvolvimento Sustentável.
							<br></br>
							<br></br>
							Órgãos públicos que nos apoiaram na organização das informações referentes aos programas públicos de coleta seletiva e promoção da reciclagem implantados nos Governos dos Estados de Minas Gerais (Programa Bolsa Reciclagem), do Ceará (Programa Bolsa Catador) e do Distrito Federal (Programa de Coleta Seletiva).
						</Typography>
					</div>
				</SubMain>
				<Partners />
			</div>
		</Main >
	);
}

export default PartnersPage;