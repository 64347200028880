export const STORE_JWT = "AUTH_STORE_JWT";
export const RESET = "AUTH_RESET";

export const storeJWT = (token) => ({
	type: STORE_JWT,
	token: token,
});

export const reset = () => ({
	type: RESET,
});
