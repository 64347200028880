/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import ModalBasic from "components/Modal";

import { useNavigate } from "react-router";

import { useMediaQuery } from 'usehooks-ts';
import { useElementSize } from 'usehooks-ts';

import logo from "assets/logo.png";
import iconSeparator from "assets/icon_space.png";
import iconCardNav from "assets/icon_cards_nav.png";
// import cardCarbon from "assets/card_carbon.png";
// import cardEconomy from "assets/card_economy.png";
// import cardRecovery from "assets/card_recovery.png";
// import cardChart2 from "assets/card_chart2.png";
import totalMaterials from "assets/total_materiais-atlas-new.png";
import totalEconomy from "assets/total_economia-atlas-new.png";
import totalCarbon from "assets/total_carbono-atlas-new.png";
import totalCollectors from "assets/total_catadores-atlas-new.png";
import logoWhite from "assets/logo_white.png";

const drawerWidth = 240;
const navItems = [
	"HOME",
	"SOBRE O ATLAS",
	"MAPA DA RECICLAGEM",
	"RECICLAGEM EM NÚMEROS",
	"CADEIA DA RECICLAGEM",
	"COLETA SELETIVA",
	"PARCEIROS",
	"BIBLIOTECA",
	"METODOLOGIA",
	"COLABORE COM O ATLAS",
	// "NOTÍCIAS E EVENTOS",
];

export default function DrawerAppBar(props) {
	const { children } = props;
	const [mobileOpen, setMobileOpen] = useState(false);

	const [cardModalOpen, setCardModalOpen] = useState(false);
	const [cardModalContent, setCardModalContent] = useState('');

	const [onMouseEnterCard1, setOnMouseEnterCard1] = useState(false);
	const [onMouseEnterCard2, setOnMouseEnterCard2] = useState(false);
	const [onMouseEnterCard3, setOnMouseEnterCard3] = useState(false);
	const [onMouseEnterCard4, setOnMouseEnterCard4] = useState(false);

	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const isMobile = useMediaQuery("(max-width: 600px)");

	const styleCard = {
		width: "14vw",
		marginLeft: '10px',
		marginRight: '15px',
		marginBottom: '10px',
		cursor: 'pointer',
	}

	const footerTextStyle = {
		fontFamily: 'montserrat',
		p: 1,
		fontWeight: 600,
	}

	const [modal, setModal] = useState(false);

	const navigateToPage = (page) => {
		switch (page) {
			case "HOME":
				navigate("/");
				break;
			case "SOBRE O ATLAS":
				navigate("/sobre");
				break;
			case "METODOLOGIA":
				navigate("/metodologia");
				break;
			case "MAPA DA RECICLAGEM":
				navigate("/");
				break;
			case "PARCEIROS":
				navigate("/parceiros");
				break;
			case "RECICLAGEM EM NÚMEROS":
				navigate("/reciclagem-em-numeros");
				break;
			case "COLETA SELETIVA":
				navigate("/coleta-seletiva");
				break;
			case "BIBLIOTECA":
				navigate("/biblioteca");
				break;
			case "COLABORE COM O ATLAS":
				navigate("/colabore");
				break;
			case "CADEIA DA RECICLAGEM":
				navigate("/cadeia");
				break;
			default:
				break;
		}
	}


	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: "center", backgroundColor: "#e0e0e0", height: "100%" }}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					margin: "10px 0",
				}}
			>
				<img src={logo} alt="logo" style={{ width: isMobile ? "30vw" : "11vw", maxWidth: 200, margin: 10 }} />
			</div>
			<Divider />
			<List sx={{ backgroundColor: "#1c83c3" }}>
				{navItems.map((item) => (
					<ListItem key={item} disablePadding>
						<ListItemButton
							sx={{ textAlign: "center", fontFamily: 'montserrat' }}
							onClick={() => { navigateToPage(item) }}
						>
							<ListItemText
								disableTypography
								primary={
									<Typography
										type="body2"
										style={{
											color: '#FFFFFF',
											fontFamily: 'montserrat',
											fontSize: '0.8rem',
										}}
									>
										{item}
									</Typography>
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	// listen to navbar height changes
	const [navbarHeight, setNavbarHeight] = useState(0);
	const [navbarRendered, setNavbarRendered] = useState(0);

	const [navBarRef, { width, height }] = useElementSize();

	useEffect(() => {
		if (height) {
			setNavbarHeight(height);
			setNavbarRendered(navbarRendered + 1);
		}

		return () => {
			setNavbarHeight(0);
		};
	}, [width, height, navBarRef]);

	const Space = (props) => (
		<div
			style={{
				height: props.h,
			}}
		>
		</div>
	)

	return (
		<Box sx={{ display: "flex" }}>
			<AppBar component="nav" sx={{ backgroundColor: '#fff' }}>
				<Toolbar sx={{ flexDirection: 'row-reverse' }} ref={navBarRef} >
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<img src={logo} alt="logo" style={{ width: isMobile ? "22vw" : "11vw", marginRight: '10px' }} />
						<img src={iconSeparator} alt="icon-separator" style={{ height: isMobile ? '8vw' : '6vw', marginRight: '10px' }} />
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="end"
							onClick={handleDrawerToggle}
							sx={{
								mr: 2,
								display: {
									sm: "block"
								},
								color: "#1c83c3",
							}}
						>
							<MenuIcon
								sx={{
									fontSize: "2.5rem"
								}}
							/>
						</IconButton>
					</div>

					{!isMobile ? (
						<>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
								}}
							>
								<Typography
									variant="h6"
									noWrap
									component="div"
									sx={{
										marginTop: "1rem",
										color: "#777777",
										fontFamily: "montserrat",
										fontWeight: "bold",
										fontSize: "1rem",
									}}
								>
									TERMÔMETRO SOCIAL E AMBIENTAL DA RECICLAGEM
								</Typography>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										noWrap: true,
									}}
								>
									<div>
										<img
											src={totalMaterials}
											alt="card-totalMaterials"
											style={{
												...styleCard,
												opacity: onMouseEnterCard1 === true ? 0.7 : 1,
											}}
											onClick={() => {
												setCardModalContent(1);
												setCardModalOpen(true);
											}}
											onMouseEnter={() => {
												setOnMouseEnterCard1(true);
											}}
											onMouseLeave={() => {
												setOnMouseEnterCard1(false);
											}}
										/>
										<img
											src={totalEconomy}
											alt="card-totalEconomy"
											style={{
												...styleCard,
												opacity: onMouseEnterCard2 === true ? 0.7 : 1,
											}}
											onClick={() => {
												setCardModalContent(2);
												setCardModalOpen(true);
											}}
											onMouseEnter={() => {
												setOnMouseEnterCard2(true);
											}}
											onMouseLeave={() => {
												setOnMouseEnterCard2(false);
											}}
										/>
										<img
											src={totalCarbon}
											alt="card-totalCarbon"
											style={{
												...styleCard,
												opacity: onMouseEnterCard3 === true ? 0.7 : 1,
											}}
											onClick={() => {
												setCardModalContent(3);
												setCardModalOpen(true);
											}}
											onMouseEnter={() => {
												setOnMouseEnterCard3(true);
											}}
											onMouseLeave={() => {
												setOnMouseEnterCard3(false);
											}}
										/>
										<img
											src={totalCollectors}
											alt="card-totalCollectors"
											style={{
												...styleCard,
												opacity: onMouseEnterCard4 === true ? 0.7 : 1,
											}}
											onClick={() => {
												setCardModalContent(4);
												setCardModalOpen(true);
											}}
											onMouseEnter={() => {
												setOnMouseEnterCard4(true);
											}}
											onMouseLeave={() => {
												setOnMouseEnterCard4(false);
											}}
										/>
									</div>
								</div>
							</div>
						</>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								width: "100%"
							}}
						>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="end"
								onClick={() => {
									setModal(true);
								}}
								sx={{
									mr: 2,
									display: {
										sm: "block"
									},
									color: "#1c83c3",
								}}
							>
								<img src={iconCardNav} alt="icon-card-nav" style={{ height: '2rem', marginRight: '10px' }} />
							</IconButton>
						</div>
					)}

				</Toolbar>
			</AppBar>

			<Box component="nav">
				<Drawer
					anchor="right"
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth
						},
					}}
				>
					{drawer}
				</Drawer>
			</Box>

			<Box component="nav">
				<Drawer
					anchor="left"
					variant="temporary"
					open={modal}
					onClose={() => {
						setModal(false);
					}}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth
						},
					}}
				>
					<>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								textAlign: "center",
							}}
						>
							<Typography
								variant="h6"
								component="div"
								sx={{
									marginTop: "1rem",
									color: "#777777",
									fontFamily: "montserrat",
									fontWeight: "bold",
									fontSize: "0.8rem",
								}}
							>
								{`TERMÔMETRO SOCIAL E AMBIENTAL DA RECICLAGEM`}
							</Typography>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									width: "100%",
									margin: 10,
								}}
							>
								<div>
									<img
										src={totalMaterials}
										alt="card-totalMaterials"
										style={{
											maxWidth: '80%',
											marginTop: 20,
											cursor: 'pointer',
										}}
										onClick={() => {
											setCardModalContent(1);
											setCardModalOpen(true);
										}}
									/>
									<img
										src={totalEconomy}
										alt="card-totalEconomy"
										style={{
											maxWidth: '80%',
											marginTop: 20,
											cursor: 'pointer',
										}}
										onClick={() => {
											setCardModalContent(2);
											setCardModalOpen(true);
										}}
									/>
									<img
										src={totalCarbon}
										alt="card-totalCarbon"
										style={{
											maxWidth: '80%',
											marginTop: 20,
											cursor: 'pointer',
										}}
										onClick={() => {
											setCardModalContent(3);
											setCardModalOpen(true);
										}}
									/>
									<img
										src={totalCollectors}
										alt="card-totalCollectors"
										style={{
											maxWidth: '80%',
											marginTop: 20,
											cursor: 'pointer',
										}}
										onClick={() => {
											setCardModalContent(4);
											setCardModalOpen(true);
										}}
									/>
								</div>
							</div>
						</div>
					</>
				</Drawer>
			</Box>

			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				sx={{
					width: "100%",
					height: "100%",
				}}
				spacing={1}
			>
				<Box
					component="main"
					sx={{
						minHeight: '70vh',
						width: "100%"
					}}
				>
					{navbarHeight > 0 && navbarRendered > 0 && (
						<Space h={navbarHeight} />
					)}

					{children}

				</Box>
				<footer
					style={{
						minWidth: "100%",
						marginTop: 0,
					}}
				>
					<div
						style={{
							backgroundColor: "#3191ca",
							height: "100px",
						}}
					/>
					<Stack
						justifyContent="center"
						alignItems="stretch"
						direction={{ xs: 'column', sm: 'row' }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
						sx={{
							backgroundColor: "#0952a0",
							minHeight: "300px",
							color: "white",
							fontFamily: "montserrat",
							padding: 5
						}}
						wrap="wrap"
					>
						<div>
							<img src={logoWhite} alt="logo" style={{ height: 75 }} />
						</div>
						<div>
							<Typography variant="h5"
								sx={{
									fontWeight: 'bold',
									p: 1,
									fontFamily: 'montserrat',
								}}
							>
								Institucional
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/sobre"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									SOBRE O ATLAS
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									MAPA DA RECICLAGEM
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/reciclagem-em-numeros"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									RECICLAGEM EM NÚMEROS
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/cadeia"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									CADEIA DE RECICLAGEM
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/coleta-seletiva"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									COLETA SELETIVA
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/parceiros"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									PARCEIROS
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/biblioteca"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									BIBLIOTECA
								</a>
							</Typography>
						</div>
						<div>
							<Typography variant="h5"
								sx={{
									fontWeight: 'bold',
									p: 1,
									fontFamily: 'montserrat',
								}}
							>
								Estudos
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/metodologia"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									METODOLOGIA DE DADOS
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="#formDownload"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									RELATÓRIO DE DADOS ANUAL
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								PESQUISAS ESPECÍFICAS
							</Typography>
						</div>
						<div>
							<Typography variant="h5"
								sx={{
									fontWeight: 'bold',
									p: 1
								}}
							>
								Colabore
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								<a href="/colabore"
									style={{
										textDecoration: "none",
										color: "#FFFFFF",
									}}
								>
									PATROCÍNIO
								</a>
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								DOAÇÕES
							</Typography>
							<Typography variant="body1" sx={footerTextStyle}>
								CONTATO COMERCIAL
							</Typography>
						</div>
					</Stack>
				</footer>
			</Stack>
			<ModalBasic
				open={cardModalOpen}
				setOpen={setCardModalOpen}
			>
				<Paper
					style={{
						maxHeight: '70vh',
						// minWidth: isMobile ? '90vw' : '',
						overflow: 'auto'
					}}>
					<List>
						<div>
							<Typography
								variant="p"
								sx={{
									fontFamily: 'montserrat',
								}}
							>
								{cardModalContent === 1 && (
									<div
										style={{
											textAlign: 'justify',
											padding: '2rem',
										}}
									>
										Valor, em toneladas, correspondente à soma de todos os materiais comercializados por cooperativas e associações brasileiras nos anos de 2020 e 2021, com registros constantes nos Bancos de Dados do ATLAS, em total de recicláveis e por tipo de material.
										<br></br>
										<br></br>
										No Mapa da Catação é possível verificar quanto foi comercializado de cada tipo de material, em cada Estado do Brasil, em cada ano (2020 ou 2021).
									</div>
								)}
								{cardModalContent === 2 && (
									<div
										style={{
											textAlign: 'justify',
											padding: '2rem',
										}}
									>
										Valor, em milhões de reais, incorporado à economia brasileira a partir da comercialização de resíduos recicláveis desviados do aterramento e dos lixões, pelas associações e cooperativas de catadores constantes nos Bancos de Dados acessados pelo ATLAS, e o número de postos de trabalho gerados nos mesmos anos.
										<br></br>
										<br></br>
										Na realidade, a quantidade de empregos gerados é maior. Estudos comprovam que a reciclagem de uma tonelada de material tem potencial de gerar 20 postos de trabalho nos diversos elos da cadeia da reciclagem, incluindo milhares de catadores/as autônomos, cuja contribuição ainda não foi contabilizada nesta edição do ATLAS.
										<br></br>
										<br></br>
										No Mapa da Catação é possível verificar quanto foi comercializado de cada tipo de material, em cada Estado do Brasil nos anos de 2020 ou 2021.
									</div>
								)}
								{cardModalContent === 3 && (
									<div
										style={{
											textAlign: 'justify',
											padding: '2rem',
										}}
									>
										Os valores informados neste ícone correspondem à quantidade de megatoneladas de CO2 equivalente, que deixou de ser emitida para a atmosfera considerando o total de materiais reciclados no ano de 2020 informado pela indústria recicladora no Brasil (valor de 11.164.406 t CO2e/ano).
										<br></br>
										<br></br>
										Deste valor, uma parcela de 3.306.660 t CO2e/ano é uma contribuição específica dos/as catadores/as de materiais recicláveis para a mitigação dos Gases de Efeito Estufa (GEE), correspondente ao total de resíduos desviados do aterramento acrescido de um montante devido ao tipo de transporte e forma de triagem realizada pelos catadores, que diferem dos modos tradicionalmente empregados pelas empresas de gestão de resíduos.
										<br></br>
										<br></br>
										No total das emissões de GEE evitadas pela reciclagem no Brasil, os catadores são responsáveis por cerca de 30%.
										<br></br>
										<br></br>
										O Relatório completo “Catadores e mitigação de GEE” será disponibilizado na BIBLIOTECA deste portal.
									</div>
								)}
								{cardModalContent === 4 && (
									<div
										style={{
											textAlign: 'justify',
											padding: '2rem',
										}}
									>
										Número de associações e cooperativas de catadores/as de materiais recicláveis devidamente legalizadas e registradas no Bancos de Dados do ATLAS, no ano de 2021 e respectivo número de catadores/catadores associados/as a estes empreendimentos.
										<br></br>
										<br></br>
										A porcentagem indicada apresenta quantas dessas associações e cooperativas  possuem condições mínimas de funcionamento, ou seja, são proprietárias de seus galpões, sendo estes  equipados com mesa ou esteira de triagem, balança e uma prensa de, no minimo, 200kg, equipamentos considerados básicos para maior produtividade dos empreendimentos.
										<br></br>
										<br></br>
										À medida em que for possível acessar dados relativos a mais organizações e aos catadores autônomos esse número irá crescer substancialmente.
										<br></br>
										<br></br>
										É possível baixar o Relatório completo do ATLAS 2022 e ver esses dados com mais detalhes neste link.
									</div>
								)}
							</Typography>
						</div>
					</List>
				</Paper>

			</ModalBasic>
		</Box >
	);
}
